import { components } from '@/@types/RecommendationApi';

export interface RecommendedItem {
  title: string;
  description: string;
  imageId: string;
  url: string;
  id: string;
  entityType: 'movie' | 'program';
}

export interface RecommendationData {
  items: RecommendedItem[] | undefined;
}

const getUrl = (item: components['schemas']['models.RecommendationItem']) => {
  switch (item.entityType) {
    case 'TV_SERIES':
    case 'SPORTS_SERIES':
    case 'NEWS_SERIES':
    case 'TV_PROGRAM':
    case 'SPORTS_PROGRAM':
    case 'NEWS_PROGRAM':
      return `https://www.sbs.com.au/ondemand/${item.entityType.toLowerCase().replace('_', '-')}/${item.slug}`;

    case 'MOVIE':
    default:
      return `https://www.sbs.com.au/ondemand/movie/${item.slug}/${item.mpxMediaID}`;
  }
};

export const fetchData = (userId: string, accessToken: string): Promise<RecommendationData | null | undefined> => {
  if (process.env.NEXT_PUBLIC_SBS_RECOMMENDATION_API_HOST) {
    const apiUrl = `${process.env.NEXT_PUBLIC_SBS_RECOMMENDATION_API_HOST}/recommended-for-you`;
    return fetch(
      apiUrl,
      {
        headers: {
          ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
        },
      },
    )
      .then((res) => { return res.json(); })
      .then((data: components['schemas']['models.RecommendationResult']) => {
        const recommendedItems: RecommendedItem[] | undefined = [];

        data?.recommendedItems?.every((item) => {
          if (
            recommendedItems.length < 3
            && item.images
            && item.description
          ) {
            const image = item.images.filter((_image) => {
              return _image.category.indexOf('2:3') !== -1 && _image.category.indexOf('BANNER') !== -1;
            });

            recommendedItems.push({
              title: item.title,
              description: item.description,
              imageId: image ? image[0].id : '',
              url: getUrl(item),
              id: item.id,
              entityType: item.entityType === 'MOVIE' ? 'movie' : 'program',
            });

            return true;
          }

          return false;
        });

        return {
          items: recommendedItems,
        };
      });
  }

  return Promise.resolve(null);
};

const RecommendationApi = {
  fetchData,
};
export default RecommendationApi;
