interface CountryData {
  country: string;
  displayName: string;
  consumption: number;
  videoId: string;
  videoItem: {
    imageURL: string;
    name: string;
    uuid: string;
  },
}

export interface CatalogueItem {
  name: string;
  uuid: string;
  imageURL: string;
}

interface RewindApiResponse {
  uuid: string;
  award: string;
  consumption: {
    consumptionMins: number;
    seriesCount: number;
    filmCount: number;
    topGenre: string;
    topGenreMins: number;
    languageCount: number;
  },
  country: CountryData[];
  language: {
    language: string;
    additionalInfo: string;
  };
  niche: {
    nicheSlider: number;
  },
  topPercentile: number;
  image: string;
  catalogueItems: CatalogueItem[];
  userInfo: {
    firstname: string;
  };
  error?: string;
}

export interface ConsumptionData {
  total: number;
  tvShows: number;
  movies: number;
  topGenre: {
    name: string;
    time: number;
  };
  languages: number;
}

export interface CountryConsumption {
  time: number;
  displayName?: string;
  topContent?: {
    title: string;
    imageUrl: string;
  }
}

export interface RewindData {
  user: {
    firstname: string;
  };
  maxCountryConsumption: {
    name: string;
    time: number;
  };
  consumptionPerCountry: Record<string, CountryConsumption>;
  shareImageUrl: string;
  nicheSlider: number;
  promoItems: CatalogueItem[];
  award?: string;
  consumption?: ConsumptionData;
  topLanguage?: string;
  topPercentile?: number;
  topLanguageType?: 'primary' | 'secondary';
}

export interface RewindApiError {
  error: string;
}

export const fetchData = (accessToken: string): Promise<RewindData | null | RewindApiError> => {
  if (process.env.NEXT_PUBLIC_REWIND_API) {
    const apiUrl = `${process.env.NEXT_PUBLIC_REWIND_API}/user`;

    return fetch(
      apiUrl,
      {
        headers: {
          ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
        },
      },
    )
      .then((res) => {
        if (res?.status === 404) {
          return null;
        }

        return res.json();
      })
      .then((data: RewindApiResponse | null) => {
        if (data === null) {
          return null;
        }

        if (data?.error) {
          return {
            error: data.error,
          };
        }

        const maxCountryConsumption = {
          name: '',
          time: 0,
        };

        const consumptionPerCountry: Record<string, CountryConsumption> = {};
        data?.country?.forEach((country) => {
          const { country: countryName, displayName = '', videoItem } = country;

          consumptionPerCountry[countryName] = {
            time: country.consumption,
            displayName: displayName || countryName,
            ...(videoItem.imageURL !== '' && {
              topContent: {
                title: videoItem.name,
                imageUrl: videoItem.imageURL,
              },
            }),
          };

          // Record the country with the highest consumption time
          if (country.consumption > maxCountryConsumption.time) {
            // Used to calculate the country highlight colors
            maxCountryConsumption.time = country.consumption;

            // The globe will center on this country on load
            maxCountryConsumption.name = countryName;
          }
        });

        return {
          maxCountryConsumption,
          consumptionPerCountry,
          ...(data.consumption.consumptionMins > 0 && {
            consumption: {
              total: data.consumption.consumptionMins,
              tvShows: data.consumption.seriesCount,
              movies: data.consumption.filmCount,
              topGenre: {
                name: data.consumption.topGenre,
                time: data.consumption.topGenreMins,
              },
              languages: data.consumption.languageCount,
            },
          }),
          topLanguage: data.language.language,
          topLanguageType: (data.language.additionalInfo === 'Top language' || data.language.language === 'English') ? 'primary' : 'secondary' as 'primary' | 'secondary',
          topPercentile: data.topPercentile,
          award: data.award,
          shareImageUrl: data.image,
          nicheSlider: data.niche.nicheSlider,

          // Reversing so that the top of the list is rendered at the front of the image stack
          promoItems: data.catalogueItems.reverse(),
          user: {
            firstname: data?.userInfo?.firstname,
          },
        };
      }).catch((error) => {
        console.error('API error', error.message);
        return { error: error.message };
      });
  }

  return Promise.resolve(null);
};

const RewindApi = {
  fetchData,
};
export default RewindApi;
