import { isMobile } from 'react-device-detect';

declare global {
  interface Window {
    adobeDataLayer: AdobeDataLayer.Root[];
    digitalData: object,
  }
}

interface UserData {
  userId: string;
  emailHash: string;
}

const userInformation: UserData = {
  userId: '',
  emailHash: '',
};

interface DataLayerType {
  init(): void;
  updateUserData(userData: UserData): void;
  events: {
    pageLoad(): void;
    screenChange(screenId: string): void;
    shareResults(): void;
    downloadResults(): void;
    recommendationMoreInfo(): void;
    addToFavourites(cardNumber: number): void;
  };
}

const DataLayer = ((): DataLayerType => {
  const trackedScreens: string[] = [];

  const generateBrowserDetails = () => {
    return {
      userAgent: navigator.userAgent,
      language: navigator.language,
      platformType: 'web browser',
      platformName: `web ${isMobile ? 'mobile' : 'desktop'}`,
    };
  };

  const addAdobeEvent = (name: string, attributes: AdobeDataLayer.EventAttributes) => {
    const payload = {
      event: name,
      ...attributes,
      browserDetails: generateBrowserDetails(),
      userAccount: {
        loggedIn: userInformation.userId !== '' ? 'login' : 'anonymous',
      },
      identification: {
        hashedEmail: userInformation.emailHash,
        janrainUUID: userInformation.userId,
      },
      clickSource: null,
    };

    if (process.env.NEXT_PUBLIC_ADOBETM_SCRIPT) {
      window.adobeDataLayer.push(payload);

      if (window.location.host !== 'rewind.sbs.com.au') {
        console.log('Adobe Datalayer: new event', name, attributes.userInteractionDetails?.interactionName);
      }
    }
  };

  return {
    init: () => {
      window.adobeDataLayer = window.adobeDataLayer || [];
      window.digitalData = window.digitalData || {
        events: [],
        page: {
          pageInfo: {
            siteName: 'SBS Rewind',
            domain: 'rewind.sbs.com.au',
          },
        },
      };
    },
    updateUserData({ userId, emailHash }) {
      userInformation.userId = userId || '';
      userInformation.emailHash = emailHash || '';
    },
    events: {
      pageLoad: () => {
        addAdobeEvent(
          'pageView',
          {
            page: {
              name: 's:ondemand:rewind',
              previousPageName: '',
              URL: window.location.href,
              title: document.title,
              pageID: '',
              siteSection: 'sbs on demand',
              siteSubSection1: 'rewind',
              siteSubSection2: '',
              siteSubSection3: '',
              siteSubSection4: '',
              pageLanguage: 'en',
            },
          },
        );
      },
      screenChange: (screenId: string) => {
        if (!trackedScreens.includes(screenId)) {
          addAdobeEvent(
            'userInteraction',
            {
              userInteractionDetails: {
                interactionName: `rewind:screen:${screenId}`,
              },
            },
          );
          trackedScreens.push(screenId);
        }
      },
      shareResults: () => {
        addAdobeEvent(
          'userInteraction',
          {
            userInteractionDetails: {
              interactionName: 'rewind:socialShare:shareResults',
            },
          },
        );
      },
      downloadResults: () => {
        addAdobeEvent(
          'userInteraction',
          {
            userInteractionDetails: {
              interactionName: 'rewind:socialShare:downloadResults',
            },
          },
        );
      },
      recommendationMoreInfo: () => {
        addAdobeEvent(
          'userInteraction',
          {
            userInteractionDetails: {
              interactionName: 'rewind:recommendation:moreInfo',
            },
          },
        );
      },
      addToFavourites: (cardNumber: number) => {
        addAdobeEvent(
          'favouritesAdded',
          {
            favouritesDetails: {
              favourites: 'added',
              location: `rewind:recommendation:card${cardNumber}`,
            },
          },
        );
      },
    },
  };
})();

export default DataLayer;
