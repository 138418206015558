import Image from 'next/image';
import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';

import DimmedOverlay from './DimmedOverlay';
import MobileMenu from './MobileMenu';
import { UserData } from './RewindSlides';
import SettingsMenu from './SettingsMenu';
import AccountIcon from './icons/AccountIcon';
import MobileMenuIcon from './icons/MobileMenuIcon';
import SettingsMenuIcon from './icons/SettingsMenuIcon';
import logoImage from '../../public/images/logo.png';

type NavigationMenuProps = {
  userData?: UserData;
  isLoggedIn: boolean | null;
  ready: boolean;
};

const NavigationMenu: FunctionComponent<NavigationMenuProps> = (props) => {
  const {
    userData,
    ready,
    isLoggedIn = false,
  } = props;
  const [openSettingsMenu, setOpenSettingsMenu] = useState(false);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const settingsMenuButtonRef = useRef<HTMLDivElement>(null);
  const settingsMenuWrapperRef = useRef<HTMLDivElement>(null);
  const mobileMenuButtonRef = useRef<HTMLButtonElement>(null);
  const mobileMenuWrapperRef = useRef<HTMLDivElement>(null);

  let hostname = 'https://www.sbs.com.au/';
  if (process.env.NEXT_PUBLIC_SBS_HOST) {
    hostname = `${process.env.NEXT_PUBLIC_SBS_HOST}`;
  }

  const onDemandHostname = `${hostname}/ondemand`;

  const logo = (
    <div>
      <h1>
        <a href={onDemandHostname} aria-label="Visit SBS On Demand homepage" className="content-center">
          <Image src={logoImage.src} alt="SBS On Demand" width="202" height="60" className="max-w-full xs:w-[202px] xl:w-[160px] 2xl:w-[202px]"/>
        </a>
      </h1>
    </div>
  );

  const menuItems = [
    {
      name: 'Home',
      link: `${onDemandHostname}`,
    },
    {
      name: 'TV Shows',
      link: `${onDemandHostname}/tv-shows`,
    },
    {
      name: 'Movies',
      link: `${onDemandHostname}/movies`,
    },
    {
      name: 'Live',
      link: `${onDemandHostname}/live`,
    },
    {
      name: 'News',
      link: `${onDemandHostname}/news`,
    },
    {
      name: 'Sport',
      link: `${onDemandHostname}/sport`,
    },
  ];

  if (userData) {
    menuItems.push({
      name: 'Favourites',
      link: `${onDemandHostname}/favourites`,
    });
  }

  const menuLinks = (
    <div className="hidden xl:flex flex-grow h-[60px] items-center mr-[40px]">
      {
        menuItems.map((menuItem) => {
          return (
            <a href={menuItem.link} className="font-ubuntu font-medium opacity-70 px-[16px] no-underline hover:opacity-100" key={menuItem.name}>
              {menuItem.name}
            </a>
          );
        })
      }
    </div>
  );

  const openModalOnSignIn = useCallback(() => {
    const event = new Event('OdWebsite_OpenSignIn');
    document.dispatchEvent(event);
  }, []);

  const openModalOnCreateAccount = useCallback(() => {
    const event = new Event('OdWebsite_OpenCreateAccount');
    document.dispatchEvent(event);
  }, []);

  const handleCloseSettingsMenu = useCallback(() => {
    setOpenSettingsMenu(false);
    if (settingsMenuWrapperRef.current) {
      settingsMenuWrapperRef.current.focus();
    }
  }, []);

  const handleCloseMobileMenu = useCallback(() => {
    setOpenMobileMenu(false);
  }, []);

  const handleClickOutsideOnMobileMenu = useCallback((e: Event) => {
    if (
      openMobileMenu
    && mobileMenuWrapperRef.current
    && !mobileMenuWrapperRef.current?.contains(e.target as HTMLElement)
    && mobileMenuButtonRef.current
    && !mobileMenuButtonRef.current.contains(e.target as HTMLElement)
    ) {
      handleCloseMobileMenu();
    }
  }, [handleCloseMobileMenu, openMobileMenu]);

  const handleClickOutsideOnSettingsMenu = useCallback((e: Event) => {
    if (
      openSettingsMenu
    && settingsMenuWrapperRef.current
    && !settingsMenuWrapperRef.current.contains(e.target as HTMLElement)
    && settingsMenuButtonRef.current
    && !settingsMenuButtonRef.current.contains(e.target as HTMLElement)
    ) {
      handleCloseSettingsMenu();
    }
  }, [handleCloseSettingsMenu, openSettingsMenu]);

  useEffect(() => {
    window.addEventListener('click', handleClickOutsideOnMobileMenu);
    window.addEventListener('click', handleClickOutsideOnSettingsMenu);
    return () => {
      window.removeEventListener('click', handleClickOutsideOnMobileMenu);
      window.removeEventListener('click', handleClickOutsideOnSettingsMenu);
    };
  }, [handleClickOutsideOnMobileMenu, handleClickOutsideOnSettingsMenu]);

  const loginButtons = (
    <div className="hidden xl:flex">
      <button
        type="button"
        className="sbs-button sbs-button-primary sbs-button-small !text-sm"
        data-modal-target="login-modal"
        data-modal-show="login-modal"
        onClick={openModalOnSignIn}
      >
        <span>
          Sign in
        </span>
      </button>
      <button
        type="button"
        className="sbs-button sbs-button-secondary sbs-button-small ml-[8px] border-[1px] border-white
        hover:text-black hover:bg-white !text-sm"
        data-modal-target="login-modal"
        data-modal-show="login-modal"
        onClick={openModalOnCreateAccount}
      >
        <span>
          Create a free account
        </span>
      </button>
    </div>
  );

  const toggleOpenSettingsMenu = useCallback(() => {
    setOpenSettingsMenu(!openSettingsMenu);
  }, [openSettingsMenu]);

  const toggleOpenMobileMenu = useCallback(() => {
    setOpenMobileMenu(!openMobileMenu);
  }, [openMobileMenu]);

  const handleListKeyDown = useCallback((e: React.KeyboardEvent) => {
    // close the menu on escape
    if (e.key === 'Escape') {
      e.preventDefault();
      handleCloseSettingsMenu();
    }
  }, [handleCloseSettingsMenu]);

  const handleMobileListKeyDown = useCallback((e: React.KeyboardEvent) => {
    // close the menu on escape
    if (e.key === 'Escape') {
      e.preventDefault();
      handleCloseMobileMenu();
    }
  }, [handleCloseMobileMenu]);

  const settingsButton = ready ? (
    <div className="hidden xl:flex flex-row items-center" ref={settingsMenuButtonRef} aria-expanded={openSettingsMenu}>
      {isLoggedIn && (
        <span className="font-normal text-[16px]">
          {userData && userData.firstname}
        </span>
      )}
      <button
        aria-label="Open Settings Menu"
        className="w-[48px] h-[48px] xs:max-sm:w-[48] xs:max-sm:h-[48] ml-[8px] p-[12px] text-xl overflow-visible text-center rounded-full opacity-[0.6] hover:bg-white/[0.1]"
        onClick={toggleOpenSettingsMenu}
        type="button"
      >
        <span>
          {isLoggedIn ? <AccountIcon/> : <SettingsMenuIcon/>}
        </span>
      </button>
    </div>
  ) : null;

  return (
    <div className="navbar z-10 top-0 fixed w-full">
      <nav
        className="h-[60px] w-full bg-black text-white flex items-center px-10 xs:justify-between xl:justify-start"
      >
        <div className="xl:hidden">
          <button className="hover:bg-white/[0.08] rounded-full fade-in-on-hover p-3 ml-[-28px]" aria-label="Open Menu" onClick={toggleOpenMobileMenu} ref={mobileMenuButtonRef} type="button">
            <MobileMenuIcon/>
          </button>
        </div>
        <div className="xs:flex xl:flex xl:relative xl:items-center xl:w-full xl:px-[60px] xl:antialiased">
          {logo}
          {menuLinks}
          {!isLoggedIn && ready && loginButtons}
          {settingsButton}
        </div>
        {/* placeholder to centre all elements in mobile view because we don't have a search button */}
        <div className="xl:hidden w-3"/>
        <div
          className={`z-[1200] min-w-[300px] absolute right-0 top-[60px] transition-all duration-[225ms] ease-out 
          ${openSettingsMenu ? 'opacity-100 visible ' : 'opacity-0 invisible'}`}
          ref={settingsMenuWrapperRef}
        >
          <SettingsMenu
            isLoggedIn={isLoggedIn}
            onClose={handleCloseSettingsMenu}
            open={openSettingsMenu}
            onListKeyDown={handleListKeyDown}
          />
        </div>
        <div
          className={`z-[1300] fixed left-0 top-0 xs:min-w-full sm:min-w-[400px] 
        overflow-y-auto h-full transition-all duration-[225ms]
        ease-out ${openMobileMenu ? 'translate-x-0 visible' : '-translate-x-full invisible'}`}
          ref={mobileMenuWrapperRef}
        >
          <MobileMenu
            userData={userData}
            onClose={handleCloseMobileMenu}
            menuItems={menuItems}
            onListKeyDown={handleMobileListKeyDown}
          />
        </div>
        {(openMobileMenu || openSettingsMenu) && <DimmedOverlay/>}
      </nav>
    </div>
  );
};

export default NavigationMenu;
