import { FunctionComponent } from 'react';

const DimmedOverlay: FunctionComponent = () => {
  return (
    <div
      className="fixed top-[60px] left-0 w-full h-full bg-black/60 overflow-hidden z-[999]"
      onWheelCapture={(e) => { e.stopPropagation(); }}
    />
  );
};

export default DimmedOverlay;
