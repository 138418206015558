import SbsLoginSdk from '@sbs/sbs-login';
import React, { FunctionComponent, useCallback, useEffect, useRef } from 'react';

interface LoginModalProps {
  sbsLogin: SbsLoginSdk | undefined;
}

const LoginModal: FunctionComponent<LoginModalProps> = (props) => {
  const { sbsLogin } = props;
  const sbsLoginEl = useRef<HTMLDivElement>(null);

  const sbsLoginCloseHandler = useCallback(() => {
    const modal = document.getElementById('login-modal');
    if (modal) {
      modal.classList.remove('block');
      modal.classList.add('hidden');
    }
  }, []);

  const closeIfClickedOutside = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    if (sbsLoginEl.current && !sbsLoginEl.current.contains(event.target as Node)) {
      sbsLoginCloseHandler();
    }
  }, [sbsLoginCloseHandler]);

  useEffect(() => {
    const openSignInHandler = () => {
      if (sbsLogin) {
        const modal = document.getElementById('login-modal');
        if (modal) {
          sbsLogin.open();
          modal.classList.remove('hidden');
          modal.classList.add('flex');
        }
      }
    };

    const openCreateAccountHandler = () => {
      if (sbsLogin) {
        const modal = document.getElementById('login-modal');
        if (modal) {
          sbsLogin.openCreateAccount();
          modal.classList.remove('hidden');
          modal.classList.add('flex');
        }
      }
    };

    const signOutHandler = () => {
      if (sbsLogin) {
        sbsLogin.signOut();
      }
    };

    document.addEventListener('OdWebsite_OpenSignIn', openSignInHandler);
    document.addEventListener('OdWebsite_OpenCreateAccount', openCreateAccountHandler);
    document.addEventListener('OdWebsite_SignOut', signOutHandler);

    if (sbsLogin && sbsLoginEl.current) {
      sbsLogin.on('close', sbsLoginCloseHandler);
    }

    return () => {
      document.removeEventListener('OdWebsite_OpenSignIn', openSignInHandler);
      document.removeEventListener('OdWebsite_OpenCreateAccount', openCreateAccountHandler);
      document.removeEventListener('OdWebsite_SignOut', signOutHandler);
      if (sbsLogin) {
        sbsLogin.off('close', sbsLoginCloseHandler);
      }
    };
  }, [sbsLogin, sbsLoginCloseHandler]);

  return (
    <div
      id="login-modal"
      className="login-modal fixed hidden inset-0 bg-black/75 overflow-scroll h-screen w-screen
      items-center justify-center z-10"
      tabIndex={-1}
      aria-hidden="true"
      onClick={closeIfClickedOutside}
      onWheelCapture={(e) => { e.stopPropagation(); }}
    >
      <div
        className="relative m-auto max-w-[600px] overflow-x-auto"
      >
        <div
          id="sbs-login"
          data-testid="sbs-login"
          ref={sbsLoginEl}
        />
      </div>
    </div>
  );
};

export default LoginModal;
