import Image from 'next/image';
import { FunctionComponent } from 'react';

import icon from './settings-menu.svg';

const SettingsMenuIcon: FunctionComponent<RewindIconsProps> = (props) => {
  const { className, alt = '' } = props;

  return (
    <Image
      className={className}
      priority
      src={icon}
      alt={alt}
    />
  );
};

export default SettingsMenuIcon;
