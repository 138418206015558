'use client';

import SbsLoginSdk from '@sbs/sbs-login';
import { get } from 'lodash';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

import LoginModal from '@/components/LoginModal';
import NavigationMenu from '@/components/NavigationMenu';
import type { UserData } from '@/components/RewindSlides';
import TestConsole from '@/components/TestConsole';
import useIsMounted from '@/hooks/useIsMounted';
import RevealJsProvider from '@/providers/RevealJsProvider';
import {
  fetchAllFavourites,
  AllFavourites as AllFavouritesData,
} from '@/services/FavouritesApi';
import { fetchData as fetchRecommendationData, RecommendationData } from '@/services/RecommendationApi';
import { fetchData as fetchRewindData, RewindApiError, RewindData } from '@/services/RewindApi';
import DataLayer from '@/utils/DataLayer';

// Client side loading of components. These require the "window" object that does not exist on the server side.
const RewindSlides = dynamic(() => { return import('@/components/RewindSlides'); }, { ssr: false });
const NrBrowserAgent = dynamic(() => { return import('@/utils/nr-browser-agent'); }, { ssr: false });

const Home = () => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
  const [ready, setReady] = useState<boolean>(false);
  const [sbsLogin, setSbsLogin] = useState<SbsLoginSdk>();
  const [userData, setUserData] = useState<UserData | undefined>(undefined);
  const [rewindData, setRewindData] = useState<RewindData | RewindApiError | null | undefined>(undefined);
  const [recommendationData, setRecommendationData] = useState<RecommendationData | null | undefined>(undefined);
  const [favouritesData, setFavouritesData] = useState<AllFavouritesData>({
    movie: [],
    program: [],
  });
  const [showTestConsole, setShowTestConsole] = useState(false);
  const [rotateDeviceMessage, setRotateDeviceMessage] = useState('');
  const isMounted = useIsMounted();

  useEffect(() => {
    if (isMobile) {
      setRotateDeviceMessage('To enhance your SBS Rewind experience, please rotate your device.');
    } else {
      setRotateDeviceMessage('To enhance your SBS Rewind experience, please expand your browser window.');
    }

    DataLayer.init();
  }, []);

  useEffect(() => {
    const handleKeypress = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.code === 'KeyT') {
        setShowTestConsole(!showTestConsole);
      }
    };

    document.addEventListener('keypress', handleKeypress);

    return () => {
      document.removeEventListener('keypress', handleKeypress);
    };
  }, [showTestConsole]);

  useEffect(() => {
    if (
      // Handles React v18's React Strict Mode multiple mounting
      (process.env.NODE_ENV !== 'development' || isMounted.current)
      && !sbsLogin
    ) {
      setSbsLogin(new SbsLoginSdk({
        apiHost: process.env.NEXT_PUBLIC_SBS_LOGIN_API_HOST,
        authApiHost: process.env.NEXT_PUBLIC_SBS_LOGIN_AUTH_API_HOST,
        showLanguageSwitcher: false,
        context: 'odwebsite',
        language: 'en',
        loadFonts: false,
        htmlEl: '#sbs-login',
      }));
    } else if (sbsLogin) {
      sbsLogin.on('ready', () => {
        setIsLoggedIn(sbsLogin.isLoggedIn());
        setReady(true);

        if (sbsLogin.isLoggedIn()) {
          sbsLogin.getEmailHash().then((emailHash) => {
            DataLayer.updateUserData({
              userId: sbsLogin.getUserId(),
              emailHash: emailHash || '',
            });
            DataLayer.events.pageLoad();
            DataLayer.events.screenChange('welcome');
          });
        } else {
          DataLayer.events.pageLoad();
          DataLayer.events.screenChange('welcome');
        }
      });

      sbsLogin.on('signInSuccess', () => {
        setIsLoggedIn(sbsLogin.isLoggedIn());
        sbsLogin.close();
        sbsLogin.getEmailHash().then((emailHash) => {
          DataLayer.updateUserData({
            userId: sbsLogin.getUserId(),
            emailHash: emailHash || '',
          });
        });
      });

      sbsLogin.on('signOutSuccess', () => {
        setIsLoggedIn(sbsLogin.isLoggedIn());
        const event = new Event('OdWebsite_SignOutSuccess');
        document.dispatchEvent(event);
        setUserData(undefined);
        setRewindData(undefined);
      });
    }
  }, [isMounted, sbsLogin]);

  useEffect(() => {
    if (sbsLogin && isLoggedIn) {
      setUserData({
        firstname: '',
        userId: process.env.NEXT_PUBLIC_TEST_USERID || sbsLogin.getUserId(),
      });

      sbsLogin.getAccessToken().then((accessToken) => {
        fetchRewindData(accessToken).then((data) => {
          setUserData({
            firstname: get(data, 'user.firstname', ''),
            userId: process.env.NEXT_PUBLIC_TEST_USERID || sbsLogin.getUserId(),
          });
          setRewindData(data);
        });

        fetchRecommendationData(
          sbsLogin.getUserId(),
          accessToken,
        ).then((data) => {
          setRecommendationData(data);
        });

        fetchAllFavourites().then((data) => {
          setFavouritesData(data);
        });
      });
    } else {
      setUserData(undefined);
    }
  }, [sbsLogin, isLoggedIn]);

  const handleTestDataChange = (json: string) => {
    const data = JSON.parse(json);
    setUserData(data.userData);
    setRewindData(data.rewindData);
    setRecommendationData(data.recommendationData);
  };

  return (
    <RevealJsProvider>
      <NrBrowserAgent/>
      <NavigationMenu
        userData={userData}
        ready={ready}
        isLoggedIn={isLoggedIn}
      />
      <div className="group-[&.ready]/body:hidden w-full h-full flex flex-col justify-center items-center opacity-0 animate-fadein animate-delay-[5000ms] animate-fill-forwards">
        <div className="border-dark-tangerine h-20 w-20 animate-spin rounded-full border-8 border-t-black"/>
      </div>
      <RewindSlides
        userData={userData}
        rewindData={rewindData}
        recommendationData={recommendationData}
        isLoggedIn={!!isLoggedIn}
        favouritesData={favouritesData}
      />
      {process.env.NEXT_PUBLIC_ENABLE_TEST_CONSOLE && showTestConsole && userData && (
        <TestConsole
          data={
            JSON.stringify({
              userData,
              rewindData,
              recommendationData,
            }, null, 4)
          }
          onChange={handleTestDataChange}
        />
      )}
      <div
        className="portrait-only hidden text-2xl h-full w-full absolute z-10 flex-col items-center justify-center gap-8 px-2 text-center"
      >
        <div className="w-[80%]">{rotateDeviceMessage}</div>
        <a
          type="button"
          className="sbs-button sbs-button-primary sbs-button-small no-underline"
          href="https://www.sbs.com.au/ondemand"
        >
          Visit homepage
        </a>
      </div>
      <LoginModal sbsLogin={sbsLogin}/>
    </RevealJsProvider>
  );
};

export default Home;
