import { FunctionComponent, useCallback } from 'react';

import { UserData } from './RewindSlides';
import AccountIcon from './icons/AccountIcon';
import CloseIcon from './icons/CloseIcon';

type MobileMenuProps = {
  userData?: UserData;
  onClose: () => void;
  menuItems: { name: string; link: string; }[];
  onListKeyDown?: (e: React.KeyboardEvent) => void;
};

const MobileMenu: FunctionComponent<MobileMenuProps> = (props) => {
  const {
    userData,
    onClose = () => {},
    menuItems,
    onListKeyDown = () => {},
  } = props;

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const openModalOnSignIn = useCallback(() => {
    const event = new Event('OdWebsite_OpenSignIn');
    document.dispatchEvent(event);
  }, []);

  const openModalOnCreateAccount = useCallback(() => {
    const event = new Event('OdWebsite_OpenCreateAccount');
    document.dispatchEvent(event);
  }, []);

  const clickLogout = useCallback(() => {
    const event = new Event('OdWebsite_SignOut');
    document.dispatchEvent(event);
    handleClose();
  }, [handleClose]);

  const menuLinks = (
    <div>
      {menuItems.map((menuItem) => {
        return (
          <a href={menuItem.link} className="font-ubuntu opacity-70 no-underline" key={menuItem.name} onKeyDown={onListKeyDown}>
            <div className="pl-[24px] pr-[16px] py-3 hover:bg-white/10 fade-in-on-hover">
              <div className="inline-flex items-center text-lg">
                {menuItem.name}
              </div>
            </div>
          </a>
        );
      })}
    </div>
  );

  return (
    <nav
      className="bg-dark-black-pearl overflow-y-auto antialiased"
      onWheelCapture={(e) => { e.stopPropagation(); }}
    >
      <button className="w-10 top-[10px] right-[5px] absolute z-10 hover:bg-white/[0.08] rounded-full fade-in-on-hover" onClick={onClose} aria-label="Close Menu" type="button" onKeyDown={onListKeyDown}>
        <CloseIcon/>
      </button>
      {userData && (
      <div className="items-center">
        <div className="min-h-[48px] pl-[24px] pr-[16px] py-4 bg-white/10">
          <div className="inline-flex items-center text-lg">
            <AccountIcon className="mr-3 flex-shrink-0 min-w-[52px] h-10"/>
            <span className="text-white/70 font-roboto font-normal leading-6">{userData.firstname}</span>
          </div>
        </div>
      </div>
      )}
      <div className="border-b-[1px] border-white/[0.1] pb-[8px]">
        {menuLinks}
      </div>
      {userData && (
      <div className="items-center border-b-[1px] border-white/[0.1] pb-[8px]">
        <div className="uppercase opacity-80 text-[0.875rem] leading-6 pt-[20px] pl-[24px] pr-[16px] py-2 text-white/50">
          <span>Settings</span>
        </div>
        <a href={process.env.NEXT_PUBLIC_MYACCOUNT_HOST} rel="noopener noreferrer" target="_blank" className="no-underline" onKeyDown={onListKeyDown}>
          <div className="min-h-[48px] font-ubuntu hover:bg-white/5 align-middle items-center flex pl-[24px] pr-[16px] py-4 fade-in-on-hover">
            <div className="inline-flex items-center">
              <span className="text-white/70 font-normal leading-6">Manage Account</span>
            </div>
          </div>
        </a>
      </div>
      )}
      {!userData && (
      <div className="items-center border-b-[1px] border-white/[0.1] pb-[8px]">
        <div className="uppercase opacity-80 text-[0.875rem] leading-6 pt-[20px] pl-[24px] pr-[16px] py-3 text-white/50">
          <span>Settings</span>
        </div>
        <button onClick={openModalOnSignIn} className="min-h-[48px] font-ubuntu hover:bg-white/5 align-middle items-center flex pl-[24px] pr-[16px] py-4 w-full fade-in-on-hover" type="button" onKeyDown={onListKeyDown}>
          <div className="inline-flex items-center">
            <span className="text-white/70 font-normal leading-6">Sign In</span>
          </div>
        </button>
        <button onClick={openModalOnCreateAccount} className="min-h-[48px] font-ubuntu hover:bg-white/5 align-middle items-center flex pl-[24px] pr-[16px] py-4 w-full fade-in-on-hover" type="button" onKeyDown={onListKeyDown}>
          <div className="inline-flex items-center">
            <span className="text-white/70 font-normal leading-6">Create Account</span>
          </div>
        </button>
      </div>
      )}
      <div className="items-center border-b-[1px] border-white/[0.1] pb-[8px]">
        <div className="uppercase opacity-80 text-[0.875rem] leading-6 pt-[20px] pl-[24px] pr-[16px] py-3 text-white/50">
          <span>Get to know us</span>
        </div>
        <a href={process.env.NEXT_PUBLIC_SBS_HOST} rel="noopener noreferrer" target="_blank" className="no-underline" onKeyDown={onListKeyDown}>
          <div className="min-h-[48px] font-ubuntu hover:bg-white/5 align-middle items-center flex pl-[24px] pr-[16px] py-3 fade-in-on-hover">
            <div className="inline-flex items-center">
              <span className="text-white/70 font-normal leading-6">Visit SBS.com.au</span>
            </div>
          </div>
        </a>
      </div>
      <div className="items-center pb-[8px]">
        <div className="uppercase opacity-80 text-[0.875rem] leading-6 pt-[20px] pl-[24px] pr-[16px] py-3 text-white/50">
          <span>Support</span>
        </div>
        <a href="https://help.sbs.com.au/hc/en-au/categories/360000172615-SBS-On-Demand" rel="noopener noreferrer" target="_blank" className="no-underline" onKeyDown={onListKeyDown}>
          <div className="min-h-[48px] font-ubuntu hover:bg-white/5 align-middle items-center flex pl-[24px] pr-[16px] py-3 fade-in-on-hover">
            <div className="inline-flex items-center">
              <span className="text-white/70 font-normal leading-6">Help</span>
            </div>
          </div>
        </a>
        <a href="https://help.sbs.com.au/hc/en-au/requests/new" rel="noopener noreferrer" target="_blank" className="no-underline" onKeyDown={onListKeyDown}>
          <div className="min-h-[48px] font-ubuntu hover:bg-white/5 align-middle items-center flex pl-[24px] pr-[16px] py-3 fade-in-on-hover">
            <div className="inline-flex items-center">
              <span className="text-white/70 font-normal leading-6">Contact Us</span>
            </div>
          </div>
        </a>
      </div>
      {userData && (
      <div className="items-center border-t-[1px] border-white/[0.1]">
        <button onClick={clickLogout} className="min-h-[48px] font-ubuntu hover:bg-white/5 align-middle items-center flex pl-[24px] pr-[16px] py-4 w-full fade-in-on-hover" onKeyDown={onListKeyDown} type="button">
          <div className="inline-flex items-center">
            <span className="text-white/70 font-normal leading-6">Sign Out</span>
          </div>
        </button>
      </div>
      )}
    </nav>
  );
};

export default MobileMenu;
