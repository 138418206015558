import React, { FunctionComponent, ReactNode, createContext, useState, useMemo } from 'react';
import Reveal from 'reveal.js';

interface RevealJsContextValue {
  sdk: Reveal.Api | undefined | null;
  setSdk: React.Dispatch<React.SetStateAction<Reveal.Api | undefined | null>>;
}

/* The context itself */
const RevealJsContext = createContext<RevealJsContextValue | undefined>(undefined);

interface RevealJsProviderProps {
  children: ReactNode;
}

/* The provider lets you change the provided components */
const RevealJsProvider: FunctionComponent<RevealJsProviderProps> = (props) => {
  const { children } = props;
  const [sdk, setSdk] = useState<Reveal.Api | undefined | null>(undefined);
  const context = useMemo(() => {
    return { sdk, setSdk };
  }, [sdk]);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <RevealJsContext.Provider value={context}>
      {children}
    </RevealJsContext.Provider>
  );
};

export const useRevealJsContext = () => {
  const onboardingContext = React.useContext(RevealJsContext);
  if (onboardingContext === undefined) {
    throw new Error('useRevealJsContext must be inside a <RevealJsProvider>');
  }
  return onboardingContext;
};

/* The components provider itself */
export default RevealJsProvider;
