import Cookies from 'js-cookie';

interface AddFavouriteResponse {
  add: {
    response: {
      result: boolean;
      detail: {
        type: 'program' | 'movie';
        itemId: string;
      };
    };
    status: boolean;
  };
}

export function addFavourite(type: 'program' | 'movie', id: string): Promise<boolean> {
  const formData = new FormData();
  formData.append('id', id);
  formData.append('type', type);

  const headers = {
    Authorization: `Bearer ${Cookies.get('sbs_session')}`,
  };

  const url = `${process.env.NEXT_PUBLIC_SBS_FAVOURITES_API_HOST}/api/v3/video_favourite/add`;

  return fetch(url, {
    method: 'POST',
    headers,
    body: formData,
  })
    .then((response) => {
      if (!response.ok) {
        if (response.status === 401) {
          console.error('Unauthorised');
        } else {
          console.error(`HTTP error! Status: ${response.status}`);
        }
        return false;
      }
      return response.json();
    })
    .then((data: AddFavouriteResponse) => {
      return data.add.response.result;
    })
    .catch((error) => {
      console.error(error);
      return false;
    });
}

export interface AllFavourites { movie: [], program: [] }

interface AllFavouritesResponse {
  all: {
    response: {
      movies: string[];
      programs: string[];
      programs_fda: string[];
    };
    status: boolean;
  };
}
export function fetchAllFavourites(): Promise<AllFavourites> {
  const headers = { Authorization: `Bearer ${Cookies.get('sbs_session')}` };
  const url = `${process.env.NEXT_PUBLIC_SBS_FAVOURITES_API_HOST}/api/v3/video_favourite/all?context=odwebsite`;

  return fetch(url, {
    method: 'GET',
    headers,
  })
    .then((response) => {
      if (!response.ok) {
        if (response.status === 401) {
          console.error('Unauthorised');
        } else {
          console.error(`HTTP error! Status: ${response.status}`);
        }
        return { movies: [], program: [] };
      }
      return response.json();
    })
    .then((data: AllFavouritesResponse) => {
      return {
        movie: data.all.response.movies,
        program: [
          ...data.all.response.programs,
          ...data.all.response.programs_fda,
        ],
      } as AllFavourites;
    })
    .catch((error) => {
      console.error(error);
      return { movie: [], program: [] };
    });
}

const FavouritesApi = {
  addFavourite,
  fetchAllFavourites,
};

export default FavouritesApi;
