import { FunctionComponent, useCallback } from 'react';

import ContactUsIcon from './icons/ContactUsIcon';
import HelpIcon from './icons/HelpIcon';
import LogOutIcon from './icons/LogOutIcon';
import ManageAccountIcon from './icons/ManageAccountIcon';
import MercatorIcon from './icons/Mercator';

type SettingsMenuProps = {
  isLoggedIn: boolean | null;
  onClose?: () => void;
  open: boolean;
  onListKeyDown?: (e: React.KeyboardEvent) => void;
};

const SettingsMenu: FunctionComponent<SettingsMenuProps> = (props) => {
  const {
    isLoggedIn,
    onClose = () => {},
    open,
    onListKeyDown = () => {},
  } = props;

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const clickLogout = useCallback(() => {
    const event = new Event('OdWebsite_SignOut');
    document.dispatchEvent(event);
    handleClose();
  }, [handleClose]);

  return (
    <div
      className={open ? 'opacity-100' : ''}
      onWheelCapture={(e) => { e.stopPropagation(); }}
    >
      <div className="bg-black-pearl rounded px-4 antialiased opacity-1 transform transition-opacity duration-[225ms] ease-out">
        {isLoggedIn && (
        <div className="items-center border-b-[1px] border-white/[0.1]">
          <div className="uppercase opacity-80 text-[0.875rem] leading-6 px-[6px] pt-[20px]">
            <span>Settings</span>
          </div>
          <a href={process.env.NEXT_PUBLIC_MYACCOUNT_HOST} rel="noopener noreferrer" target="_blank" className="no-underline" onKeyDown={onListKeyDown} onClick={handleClose}>
            <div className="min-h-[48px] font-ubuntu text-[0.875rem] hover:bg-white/5 align-middle items-center flex px-[6px] py-[16px] fade-in-on-hover">
              <div className="inline-flex items-center">
                <ManageAccountIcon className="min-w-0 mr-3"/>
                Manage Account
              </div>
            </div>
          </a>
        </div>
        )}
        <div className="items-center border-b-[1px] border-white/[0.1]">
          <div className="uppercase opacity-80 text-[0.875rem] leading-6 mt-2 px-[6px] pt-[16px]">
            <span>Get to know us</span>
          </div>
          <a href={process.env.NEXT_PUBLIC_SBS_HOST} rel="noopener noreferrer" target="_blank" className="no-underline" onKeyDown={onListKeyDown} onClick={handleClose}>
            <div className="min-h-[48px] font-ubuntu text-[0.875rem] hover:bg-white/5 align-middle items-center flex px-[6px] py-[16px] fade-in-on-hover">
              <div className="inline-flex items-center">
                <MercatorIcon className="min-w-0 mr-3"/>
                Visit SBS.com.au
              </div>
            </div>
          </a>
        </div>
        <div className="items-center">
          <div className="uppercase opacity-80 text-[0.875rem] leading-6 mt-2 px-[6px] pt-[16px]">
            <span>Support</span>
          </div>
          <a href="https://help.sbs.com.au/hc/en-au/categories/360000172615-SBS-On-Demand" rel="noopener noreferrer" target="_blank" className="no-underline" onKeyDown={onListKeyDown} onClick={handleClose}>
            <div className="min-h-[48px] font-ubuntu text-[0.875rem] hover:bg-white/5 align-middle items-center flex px-[6px] py-[16px] fade-in-on-hover">
              <div className="inline-flex items-center">
                <HelpIcon className="min-w-0 mr-3"/>
                Help
              </div>
            </div>
          </a>
          <a href="https://help.sbs.com.au/hc/en-au/requests/new" rel="noopener noreferrer" target="_blank" className="no-underline" onKeyDown={onListKeyDown} onClick={handleClose}>
            <div className="min-h-[48px] font-ubuntu text-[0.875rem] hover:bg-white/5 align-middle items-center flex px-[6px] py-[16px] fade-in-on-hover">
              <div className="inline-flex items-center">
                <ContactUsIcon className="min-w-0 mr-3"/>
                Contact Us
              </div>
            </div>
          </a>
        </div>
        {isLoggedIn && (
        <div className="items-center border-t-[1px] border-white/[0.1]">
          <button className="min-h-[48px] font-ubuntu text-[0.875rem] hover:bg-white/5 align-middle items-center flex px-[6px] w-full fade-in-on-hover" onClick={clickLogout} type="button" onKeyDown={onListKeyDown}>
            <div className="inline-flex items-center">
              <LogOutIcon className="min-w-0 mr-3"/>
              Sign Out
            </div>
          </button>
        </div>
        )}
      </div>
    </div>
  );
};

export default SettingsMenu;
