import { debounce } from 'lodash';
import { ChangeEvent, FunctionComponent } from 'react';

interface TestConsoleProps {
  data: string;
  onChange?: (data: string) => void;
}

const TestConsole: FunctionComponent<TestConsoleProps> = (props) => {
  const { data, onChange } = props;

  const handleChange = debounce((event: ChangeEvent<HTMLTextAreaElement>) => {
    const { target } = event;
    if (onChange) {
      onChange(target.value);
    }
  }, 1500);

  return (
    <div className="absolute bottom-0 z-10 opacity-10 hover:opacity-30">
      <h2>Test Console: current data (edit to change values on page)</h2>
      <textarea
        className="text-black w-[600px] h-[80px] hover:h-[350px] p-4 mt-1"
        onChange={handleChange}
        onWheelCapture={(e) => { e.stopPropagation(); }}
      >
        {data}
      </textarea>
    </div>
  );
};

export default TestConsole;
