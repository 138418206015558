import { useEffect, useRef } from 'react';

// Handles React v18's React Strict Mode multiple mounting
// Ref: https://legacy.reactjs.org/docs/strict-mode.html#ensuring-reusable-state
const useIsMounted = () => {
  const isMounted = useRef(false);

  useEffect(() => {
    return () => {
      isMounted.current = true;
    };
  }, []);

  return isMounted;
};

export default useIsMounted;
